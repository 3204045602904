<template>
  <div class="home">
    <Header></Header>
    <block_1></block_1>
    <bottom_1></bottom_1>


    <div class="in"></div>

  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import block_1 from './com/block_1.vue'
import bottom_1 from '../../components/bottom_1.vue'

export default {
  data() {
    return {

    }
  },
  methods: {

  },
  components: {block_1,bottom_1,Header}
}
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;

  .in {
    width: 100%;
    height: 200px;
    background-color: #dae2ff;
    background-image: url('https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2024-4-29/bg.png');
    background-size: 100%;
    box-sizing: border-box;
  }
}
</style>
