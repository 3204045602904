import { render, staticRenderFns } from "./bottom_1.vue?vue&type=template&id=4721cbee&scoped=true"
import script from "./bottom_1.vue?vue&type=script&lang=js"
export * from "./bottom_1.vue?vue&type=script&lang=js"
import style0 from "./bottom_1.vue?vue&type=style&index=0&id=4721cbee&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4721cbee",
  null
  
)

export default component.exports