<template>
  <div class="result" v-if="view">
    <div class="main">
      <div class="resultTop"></div>
      <div class="tol">免费精准报价服务</div>
      <div class="i-block_1"><input placeholder="请输入姓名" v-model="Name"/></div>
      <div class="i-block_2"><input placeholder="请输入公司名称" v-model="CompanyName"/></div>
      <div class="i-block_3"><input placeholder="请输入联系方式" v-model="UserPhone"/></div>
      <div class="i-block_4" @click="setdata">提交</div>
      <div class="i-block_5">提交后。我们将会在24小时内与您联系，<br>请注意接所电话</div>
      <div class="exit" @click="exit"></div>
    </div>
  </div>
</template>

<script>
import {add_b} from "@/api/Equipment_a";
import {Dialog, Notify} from "vant";

export default {
  data() {
    return {
      view: false,
      Name: null,
      CompanyName: null,
      UserPhone: null,
    }
  },
  methods: {
    setdata() {
      let Business_id = localStorage.getItem('Business_id')
      let CompanyName = this.CompanyName
      let UserPhone = this.UserPhone
      let Name = this.Name

      if (!CompanyName||!UserPhone||!Name){
        Notify({type: 'warning', message: '请输入必填项'});
        return
      }
      if (/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(this.UserPhone)) {
        add_b({Business_id, CompanyName, UserPhone, Name}).then(res => {
          if (res.errorCode == 200) {
            Notify({type: 'success', message: '提交成功'});
            this.exit()
          } else {
            Notify({type: 'warning', message: res.errorMsg});
          }
        })
      }else {
        Dialog.alert({
          message: '手机号码不符合规则',
        });
      }
    },
    open() {
      this.view = true
      this.closeData()
    },

    exit() {
      this.closeData()
      this.view = false
    },
    closeData() {
      this.Name = null
      this.CompanyName = null
      this.UserPhone = null
    }
  }
}
</script>


<style scoped lang="scss">
.result {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 5;

  .main {
    width: 350px;
    height: 450px;
    background-image: url("https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2023-6-10/resultBg.png");
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -175px;
    margin-top: -225px;

    .resultTop {
      width: 120px;
      height: 100px;
      background-image: url("https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2023-6-10/resultTop.png");
      background-size: 100% 100%;
      position: absolute;
      margin-left: -60px;
      left: 50%;
      top: -40px;
    }

    .tol {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      top: 80px;
    }

    .i-block_1 {
      color: #999999;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 130px;
      font-size: 13px;
    }

    .i-block_2 {
      color: #999999;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 190px;
      font-size: 13px;
    }

    .i-block_3 {
      color: #999999;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 250px;
      font-size: 13px;
    }

    .i-block_4 {
      position: absolute;
      text-align: center;
      top: 330px;
      font-size: 15px;
      color: #FFFFFF;
      width: 280px;
      left: 50%;
      margin-left: -140px;
      background-color: #3862FF;
      height: 50px;
      line-height: 50px;
      border-radius: 10px;
    }

    .i-block_5{
      position: absolute;
      text-align: center;
      top: 390px;
      font-size: 13px;
      color: #999999;
      width: 300px;
      left: 25px;
    }


    .exit {
      width: 20px;
      height: 20px;
      background-image: url("../../../assets/image/exit-1.png");
      background-size: 100% 100%;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}

input {
  display: block;
  width: 80%;
  height: 30px;
  border: 1px solid #ededed;
  background: none;
  text-indent: 1rem;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 14px;
  background-color: #f4f4f4;
  color: #b5b5b5;
  //color: #dcdcdc;
}

input:focus {
  outline: none;
  //border: none;
}
</style>