import request from "../utils/request";


//获取jsapi_ticket
export const wxTicket = (query) => {
    return request({
        url: "https://wrtz.3qlab.com/api/website/Login/wx/ticket",
        method: "get",
        params: query,
    });
};


