<template>
  <div class="bottom">
    <div class="user"></div>
    <div class="title_1">一对一专属客服</div>
    <div class="title_2">精准快速反馈</div>
    <div class="title_3" @click="open">免费精准报价</div>
    <accurate ref="accurate"></accurate>
  </div>
</template>

<script>
import accurate from '../views/Home/com/accurate.vue'
export default {
  data(){
    return{
      Tclass:'首页'
    }
  },
  components:{accurate},
  methods:{
    open(){
      this.$refs.accurate.open()
    },
    upClass(value) {
      this.Tclass = value
      this.$parent.upClass(value)
    }
  }
}
</script>

<style scoped lang="scss">
.bottom {
  width: 100%;
  height: 70px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-image: url("../assets/image/bottom_1.png");
  background-size: 100% 100%;
  .user{
    background-image: url("../assets/image/bottom_1-user.png");
    background-size: 100% 100%;
    position: absolute;
    width: 50px;
    height: 50px;
    left: 15px;
    top: 12px;
  }
  .title_1{
    font-family: 'PuHuiTi';
    position: absolute;
    left: 90px;
    top: 10px;
    color: #333333;
    font-size: 17px;

  }
  .title_2{
    font-family: 'PuHuiTi';
    position: absolute;
    left: 90px;
    top: 38px;
    color: #999999;
    font-size: 14px;
  }
  .title_3{
    font-family: 'PuHuiTi';
    width: 150px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    background-color: #3862ff;
    color: #ffffff;
    position: absolute;
    left: 220px;
    top: 15px;
  }

}
</style>