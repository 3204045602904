<template>
  <div class="Header">
    小助手
    <img class="code" alt="" src="../assets/image/code.png" @click="wxShare">
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {wxTicket} from '../api/home'

export default {
  data() {
    return {
      code: false
    }
  },
  mounted() {

  },
  methods: {
    wxShare() {
      let url = window.location.href;
      wxTicket({url}).then((res) => {

        let signature = res.payload.signature;
        let noncestr = res.payload.noncestr;
        let timestamp = res.payload.timestamp;
        wx.config({
          appId: "wxa8ce8daa4ddb25f2", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: noncestr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表
        });
        wx.ready(() => {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
              let regex = /,(.+)/;
              let match = result.match(regex);
              if (match && match.length > 1) {
                alert(match)
                let extractedString = match[1]; // 提取的字符串
                location.href = 'https://hsquotation.3qlab.com?EquipmentID=' + extractedString
              }
            },
            fail: err => {
              console.log(err)
            }
          });
        })
      });
    },
    openLogin() {
      this.$router.push('/Login')
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: intext;
  src: url('https://hz-mayi.nos-eastchina1.126.net/2021-11-6/VonwaonBitmap-12px.ttf');
}

video {
  width: 90%;
  height: 170px;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}


.Header {
  width: 100%;
  height: 60px;
  position: relative;
  background-color: #FFFFFF;
  text-align: center;
  line-height: 60px;
  font-size: 15px;

  .code {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 15px;
    top: 20px;
  }

  .codeView {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .scan-line {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 3px;
      /* background-color: #57bf9a; */
      background-image: linear-gradient(to right, transparent, #57bf9a, transparent);
      animation: scanAnimation 2s infinite;
    }

    .hint-msg {
      position: absolute;
      bottom: 50px;
      width: 100%;
      color: #ffffff;
      text-align: center;
    }

    @keyframes scanAnimation {
      0% {
        top: 40%;
      }
      100% {
        top: 60%
      }
    }
  }
}


</style>
